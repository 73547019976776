import type { SvgIcons } from 'assets/icons/svg-icons';

interface SubmitDialogButtonsConfig {
  id: number;
  icon: SvgIcons;
  label: string;
  href: string;
}

export const submitDialogButtonsConfig: SubmitDialogButtonsConfig[] = [
  {
    id: 1,
    icon: 'dapp',
    label: 'common.submitDialog.buttons.1',
    href: '#',
  },
  {
    id: 2,
    icon: 'newspaper',
    label: 'common.submitDialog.buttons.2',
    href: '#',
  },
  {
    id: 3,
    icon: 'scroll-text',
    label: 'common.submitDialog.buttons.3',
    href: '#',
  },
];
